<template>
    <div class="policyDetails">
        <!-- <van-nav-bar title="政策指引详情" left-arrow @click-left="$router.go(-1)"></van-nav-bar> -->
        <van-row class="menu-policy">
            <van-col span="24" class="menu-policy-title">{{ policyDetails.title }}</van-col>
            <van-col span="20" class="menu-policy-source">{{ policyDetails.source }}丨{{ policyDetails.createTime
            }}</van-col>
            <van-col span="4" class="menu-policy-viewNum">{{ policyDetails.viewNum }}人浏览</van-col>
            <van-col span="24" class="menu-policy-content">
                <div v-html="policyDetails.content"></div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: "PolicyDetails",
    data() {
        return {
            policyDetails: null
        }
    },
    created() {
        this.$ajax.get('/api/healthknow/getHealthKnowledgeById', {
            params: {
                knowledgeId: this.$route.query.id
            }
        }).then((response) => {
            this.policyDetails = response.data.data
        })
    },
    methods: {}
}
</script>

<style lang="less">
.policyDetails {
    .van-icon {
        font-size: 19px;
        color: #5E5E5E !important;
    }

    .van-nav-bar__title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 44px;
    }

    .menu-policy {
        padding: 0 15px 20px;

        .menu-policy-title {
            font-size: 21px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            padding: 20px 0 18px;
        }

        .menu-policy-source {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #5E5E5E;
            line-height: 31px;
        }

        .menu-policy-viewNum {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #5E5E5E;
            line-height: 31px;
            text-align: right;
        }

        .menu-policy-content {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #333333;
            line-height: 30px;
        }
    }
}
</style>