<template>
  <div class="member">
    <div class="member-info">
      <van-image
        class="header-image"
        round
        width="100px"
        height="100px"
        :src="$store.state.userInfo.headImgUrl"
      />
      <div class="user-name">{{ $store.state.userInfo.nickname }}</div>
    </div>
    <div class="menu-box">
      <van-cell title="手机号" icon="phone-o" is-link :value="$store.state.userInfo.phone">
      </van-cell>

      <van-cell title="关于我们" icon="friends-o" is-link  @click="show()">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      </van-cell>

<!--      <van-cell title="设置" icon="setting-o" is-link to="setting">
        &lt;!&ndash; 使用 right-icon 插槽来自定义右侧图标 &ndash;&gt;
      </van-cell>-->
      <van-cell icon="info-o"  title="当前版本" >v1.0.0</van-cell>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'Member',
  data () {
    return {

    }
  },
  methods: {
    show () {
      Dialog.alert({
        title: '关于我们',
        message: '关于我们'
      }).then(() => {
        // on close
      })
    }
  }
}
</script>

<style scoped lang="less">
.member{
  background-color: #F4F4F4;
  min-height: 100vh;
  .member-info{
    text-align: center;
    height: 124px;
    padding: 20px 0;
    background-color: #ffffff;
    .header-image{
      margin: 0 auto;
    }
    .user-name{
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #030303;
    }
  }
  .menu-box{
    margin-top: 20px;
    .van-cell__left-icon{
      font-size: 20px;
      color: #BB0720;
    }
  }
}
</style>
