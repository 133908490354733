import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'
import Member from '../views/Member'
// import Login from '../views/Login'
import Policy from '../views/PolicyList'
import PolicyDetails from '../views/PolicyDetails'
import Video from '../views/VideoList'
import VideoDetails from '../views/VideoDetails'

Vue.use(Router)

let routers = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
  {
    path: '/policyDetails',
    name: 'policyDetails',
    component: PolicyDetails
  },
  {
    path: '/video',
    name: 'video',
    component: Video
  },
  {
    path: '/videoDetails',
    name: 'videoDetails',
    component: VideoDetails
  },
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: Login
  // },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/Success')
  }
]

export default new Router({
  mode: 'hash',
  routes: routers
})
