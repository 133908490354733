<template>
  <div class="home">
    <div class="image-carousel">
      <img src="@/assets/images/background1.png" style="width: 100%;" />
    </div>
    <van-row class="menu-list">
      <van-col span="6" class="menu-list-item">
        <router-link to="video?type=T001">
          <img src="@/assets/images/treatMed.png" />
          <span class="item">人工智能</span>
        </router-link>
      </van-col>
      <van-col span="6" class="menu-list-item">
        <router-link to="video?type=M0001">
          <img src="@/assets/images/injuryMed.png" />
          <span class="item">计算机科学与技术</span>
        </router-link>
      </van-col>
      <van-col span="6" class="menu-list-item birth">
        <router-link to="video?type=T002">
          <img src="@/assets/images/bearMed.png" />
          <span class="item">软件工程</span>
        </router-link>
      </van-col>
      <van-col span="6" class="menu-list-item retirement">
        <router-link to="video?type=M0006">
          <img src="@/assets/images/retirement1.png" />
          <span class="item">物联网工程</span>
        </router-link>
      </van-col>
      <van-col span="6" class="menu-list-item work">
        <router-link to="video?type=M0005">
          <img src="@/assets/images/workMed.png" />
          <span class="item">信息对抗</span>
        </router-link>
      </van-col>
      <van-col span="6" class="menu-list-item">
        <router-link to="video?type=M004">
          <img src="@/assets/images/quickMed.png" />
          <span class="item">大数据</span>
        </router-link>
      </van-col>
      <van-col span="6" class="menu-list-item">
        <router-link to="video">
          <img src="@/assets/images/allMed.png" />
          <span class="item">全部</span>
        </router-link>
      </van-col>
    </van-row>
    <van-cell title="视频课程" value="更多>>" to="video" class="vanCell" />
    <van-row class="menu-video">
      <van-col span="8" v-for="(item, index) in videoList" :key="item.id" :class="'menu' + index">
        <router-link :to="'videoDetails?id=' + item.lectureHallId">
          <span><img :src="item.imageUrl" /></span>
          <span class="title">{{ item.title }}</span>
          <span class="browse">{{ item.playNum }}人浏览</span>
        </router-link>
      </van-col>
    </van-row>
    <van-cell title="图文专题" value="更多>>" to="policy" class="vanCell" style="padding-top: 5px;" />
    <van-row class="menu-policy">
      <van-col span="24" class="menu-policy-list" v-for="(item, index) in policyList" :key="index">
        <router-link :to="'policyDetails?id=' + item.knowledgeId">
          <span class="title">{{ item.title }}</span>
          <span class="browse">{{ item.createTime }}</span>
        </router-link>
      </van-col>
      <!-- <van-col span="16" class="menu-policy-list">
        <router-link to="">
          <span class="title">【西安人社局视频导办】就业困难人员社保补贴政策</span>
          <span class="browse">2068人浏览丨2023-05-22</span>
        </router-link>
      </van-col>
      <van-col span="8" class="menu-policy-list">
        <router-link to="">
          <img src="@/assets/images/img.png" />
        </router-link>
      </van-col>
      <van-col span="24" class="menu-policy-list">
        <router-link to="">
          <span class="title">“稳就业”“兴人才”“保民生”“促和谐”西安人社</span>
          <span class="browse">2068人浏览丨2023-05-22</span>
          <img class="image" src="@/assets/images/img.png" />
          <img class="image" src="@/assets/images/img.png" />
          <img class="image" src="@/assets/images/img.png" />
        </router-link>
      </van-col> -->
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      videoList: [],
      policyList: []
    }
  },
  created() {
    this.getVideoList();
    this.getPolicyList();
  },
  methods: {
    getVideoList() {
      this.videoList = []
      this.$ajax.get('/api/lecture/list', {
        params: {
          currPage: 1,
          pageSize: 3
        }
      }).then((response) => {
        this.videoList = response.data.data.list
      })
    },
    getPolicyList() {
      this.policyList = []
      this.$ajax.get('/api/healthknow/getHealthKnowledgeList', {
        params: {
          pageNum: 1,
          dataSize: 3,
          orderKey: 'viewnum_desc'
        }
      }).then((response) => {
        this.policyList = response.data.data
      })
    }
  }
}
</script>

<style lang="less">
.home {
  min-height: 100vh;
  margin-bottom: 30px;


  .menu-list {
    overflow: hidden;
    top: -30px;
    height: 180px;
    padding: 0 10px;
    position: relative;
    margin-bottom: -30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../assets/images/bag2x.png");

    .birth {
      margin-top: 18px !important;
    }

    .retirement {
      margin-top: 20px !important;
    }

    .work {
      margin-top: 17px !important;
    }

    .menu-list-item {
      text-align: center;
      height: 60px;
      margin-top: 14px;

      .item {
        display: block;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #050505;
        line-height: 18px;
      }
    }
  }

  .vanCell {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #151414;

    .van-cell__value {
      font-size: 12px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #B6B6C1;
    }
  }

  .menu-video {
    height: 196px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../assets/images/bag2.png");

    .van-col {
      // margin-top: 12px;

      img {
        width: 100px;
        height: 120px;
        object-fit: cover;
      }
    }

    .title {
      display: block;
      font-size: 11px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .browse {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      padding-top: 4px;
      display: block;
    }

    .menu0 {
      padding-left: 23px;
    }

    .menu1 {
      padding: 0 10px;
    }

    .menu2 {
      padding-right: 23px;
    }
  }

  .menu-policy {
    padding: 0px 15px;

    .menu-policy-list {
      border-bottom: 1px solid #f2f2f2;
      padding: 15px 0px 10px;

      .title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }

      .browse {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin: 10px 0;
        display: block;
      }

      .image {
        margin-right: 7px;

        &:last-child {
          margin-right: 0px !important;
        }
      }

    }
  }
}
</style>
