<template>
    <div class="videoDetails">
        <!-- <van-nav-bar title="视频" left-arrow @click-left="$router.go(-1)" /> -->
        <div class="video-container">
            <video controls :src="videoDetails.videoUrl" height="260"></video>
        </div>
        <van-tabs v-model="active">
            <van-tab title="视频" name="vide">
                <h4 class="title">{{ videoDetails.title }}</h4>
                <div class="play-volume">{{ videoDetails.playNum }}次播放</div>
                <div class="content" v-html="videoDetails.content"></div>
                <div class="foot">
                    <img :src="videoDetails.img" />
                    {{ videoDetails.source }}
                </div>
            </van-tab>
            <van-tab title="评论" name="remark">
                <template #title>评论 <van-badge v-if="active === 'vide'" :content="content" /></template>
                <div class="remark">全部评论<span class="remark-number">（1568）</span></div>
                <van-row>
                    <div v-for="item in remarkList" :key="item.id" class="video-list">
                        <van-col span="24" class="video-list-item">
                            <van-col span="4"><img src="" /></van-col>
                            <van-col span="16">
                                <div class="title">{{ item.title }}</div>
                                <div class="time">{{ item.time }}</div>
                            </van-col>
                            <van-col span="4" class="like-left">
                                <span @click="toggleLike(item)" :class="{ like: item.liked }">
                                    {{ item.number }}<van-icon :name="!item.liked ? 'good-job-o' : 'good-job'" size="19" />
                                </span>
                            </van-col>
                        </van-col>
                        <van-col span="4" />
                        <van-col span="20" class="cont">{{ item.content }}</van-col>
                        <van-col span="24" style="border: 1px solid #f2f2f2;" />
                    </div>
                </van-row>
                <div class="video-foot">
                    <van-field class="video-input" v-model="value" placeholder="说点什么吧..." />
                    <span class="foot-like">
                        <span :class="{ isLike: isLike }">
                            <van-icon :name="isLike ? 'star' : 'star-o'" size="19" @click="likeClick" />
                        </span>
                        <span class="like-left" :class="{ like: payNumbers.liked }">
                            {{ payNumbers.number }}<van-icon :name="!payNumbers.liked ? 'good-job-o' : 'good-job'" size="19"
                                @click="toggleLike(payNumbers)" />
                        </span>
                    </span>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
    name: "VideoDetails",
    data() {
        return {
            active: 1,
            value: null,
            content: 1568,
            videoDetails: null,
            payNumber: 26,
            isLike: false,
            payNumbers: {
                liked: false,
                number: 26,
            },
            remarkList: [
                {
                    img: '',
                    number: 8,
                    liked: false,
                    title: "豆苗",
                    time: "1分钟前",
                    content: "受益匪浅！"
                }, {
                    img: '',
                    number: 4,
                    liked: false,
                    title: "喵喵喵",
                    time: "30分钟前",
                    content: "老师讲的太好了"
                }, {
                    img: '',
                    number: 2,
                    liked: false,
                    title: "喵喵喵",
                    time: "30分钟前",
                    content: "心里一直都有这个疑问，今天看到这个视频真是太好了"
                }
            ]
        }
    },
    created() {
        this.$ajax.get('/api/lecture/getLecture', {
            params: {
                lectureHallId: this.$route.query.id
            }
        }).then((response) => {
            this.videoDetails = response.data.data
        })
    },
    methods: {
        likeClick() {
            this.isLike = !this.isLike
        },
        toggleLike(row) {
            if (!row.liked) {
                row.number++;
            } else {
                row.number--;
            }
            row.liked = !row.liked
        },
    }
}
</script>

<style lang="less">
.videoDetails {
    min-height: 100vh;

    .van-icon {
        font-size: 19px;
        color: #5E5E5E !important;
    }

    .video-page {
        height: 100%;
    }

    .video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 44px);
    }

    video {
        width: 100%;
        outline: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
    }

    .van-tab {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;

        .van-badge {
            font-size: 10px;
            font-family: PingFang;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
            background-color: #EE6D68;
            border-radius: 7px;
        }
    }

    .van-tab--active {
        font-weight: bold;
    }

    .van-tabs {
        padding: 0 15px;

        .van-tabs__wrap {
            border-bottom: 1px solid #F2F2F2;
        }
    }

    .title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 10px;
    }

    .play-volume {
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #9D9D9D;
        line-height: 18px;
    }

    .content {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #5E5E5E;
        line-height: 21px;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
    }

    .foot {
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
        padding-top: 17px;
    }

    .remark {
        padding-top: 17px;
        font-size: 14px;
        font-family: PingFang;
        font-weight: bold;
        color: #333333;
        line-height: 18px;

        .remark-number {
            font-size: 12px;
            font-family: PingFang;
            font-weight: 500;
            color: #9D9D9D;
            line-height: 18px;
        }
    }

    .van-row {
        margin-top: 28px;

        .video-list {
            .title {
                font-size: 14px;
                font-family: PingFang;
                font-weight: 500;
                color: #5E5E5E;
                line-height: 18px;
                margin-bottom: 5px;
            }

            .time {
                font-size: 12px;
                font-family: PingFang;
                font-weight: 500;
                color: #9D9D9D;
                line-height: 18px;
            }

            .cont {
                font-size: 14px;
                font-family: PingFang;
                font-weight: 500;
                color: #333333;
                line-height: 18px;
                padding: 13px 0 15px;
            }

            .like-left {
                text-align: right;
                font-size: 14px;
                font-family: PingFang;
                font-weight: 500;
                color: #999999;

                .van-icon {
                    vertical-align: middle;
                    margin-left: 5px;
                    margin-top: -6px;
                }
            }

            .like {
                color: #EE6D68;

                .van-icon {
                    color: #EE6D68 !important;
                }
            }

            .video-list-item {
                margin-top: 17px;
            }
        }
    }

    .video-foot {
        display: flex;
        margin-top: 33px;
        justify-content: center;
        align-items: center;

        .video-input {
            line-height: 34px;
            background: #F2F2F2;
            border-radius: 2px;
            padding: 0 5px;
            width: 74%;
            margin-right: 20px;
            display: inline-block;
        }

        .like-left {
            font-size: 14px;
            font-family: PingFang;
            font-weight: 500;
            color: #999999;
            margin-left: 10px;

            .van-icon {
                vertical-align: middle;
                margin-left: 5px;
                margin-top: -6px;
            }
        }

        .like {
            color: #EE6D68;

            .van-icon {
                color: #EE6D68 !important;
            }
        }

        .isLike {
            .van-icon {
                color: #EEC068 !important;
            }
        }
    }
}
</style>