import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/fonts/iconfont.css'
import ajax from './http/ajax'

Vue.prototype.$ajax = ajax
Vue.use(Vant)
Vue.config.productionTip = false
if (!store.state.userInfo.userType) {
  store.commit('setUserType', localStorage.getItem('userType'))
  store.commit('setUserInfo', JSON.parse(localStorage.getItem('userInfo')))
}
router.beforeEach((to, form, next) => {
  next()
  // if (to.name === 'login') {
  // } else {
  //   if (localStorage.getItem('Authorization')) {
  //     next()
  //   } else {
  //     // next('login')
  //     next()
  //   }
  // }
})
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
