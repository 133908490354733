<template>
    <div class="video">
        <!-- <van-nav-bar title="热门视频" left-arrow @click-left="$router.go(-1)"></van-nav-bar> -->
        <van-tabs v-model="active" @click="onClick">
            <van-tab v-for="item in tabsList" :title="item.title" :name="item.name">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad(isApproved)">
                    <div v-for="(item, index) in videoList" :key="item.index" class="video-card">
                        <van-cell center size="large">
                            <template slot="title">
                                <img :src="item.imageUrl" class="video-img" @click="linkeVideo(item)" />
                                <span class="video-title" @click="linkeVideo(item)">{{ item.title }}</span>
                                <span class="video-cont">{{ item.source }}</span>
                            </template>
                            <template slot="label">
                                <span class="video-left">{{ item.source }}</span>
                                <div class="clickLike">
                                    <span :class="{ collect: item.isLike }">
                                        <van-icon name="star" size="16" @click="likeClick(item)" />
                                        {{ item.favCount }}
                                    </span>
                                    <span class="video-like" :class="{ like: item.liked }" @click="toggleLike(item)">
                                        <van-icon name="good-job" size="18" />
                                        {{ item.playNum }}
                                    </span>
                                </div>
                            </template>
                        </van-cell>
                    </div>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
    name: "Video",
    data() {
        return {
            loading: true,
            finished: false,
            active: "",
            tabsList: [
                {
                    title: "推荐",
                    name: ''
                },
                {
                    title: "人工智能",
                    name: 'M0006'
                },
                {
                    title: "计算机科学",
                    name: 'M0005'
                },
                {
                    title: "软件工程",
                    name: 'M004'
                },
                {
                    title: "大数据",
                    name: 'T002'
                },
                {
                    title: "物联网工程",
                    name: 'M0001'
                },
                {
                    title: "信息对抗",
                    name: 'T001'
                }
            ],
            videoList: []
        }
    },
    created() {
        this.getDataList()
        this.active = this.$route.query.type
    },
    methods: {
        getDataList() {
            this.$ajax.get('/api/lecture/list', {
                params: {
                    currPage: this.page,
                    pageSize: 10,
                    catagoryCode: this.active
                }
            }).then((response) => {
                if (response.data.data.code === 500) {
                    this.finished = true
                    return
                }
                if (response.data.data.list.length === 0) {
                    this.finished = true
                    return
                }
                this.videoList = response.data.data.list
                this.videoList.map(item => {
                    item.isLike = false;
                    item.liked = false
                })
            })
            this.finished = true;
            this.loading = false;
        },
        linkeVideo(row) {
            this.$router.push('/videoDetails?id=' + row.lectureHallId);
        },
        onClick() {
            this.getDataList()
        },
        onLoad() {
            this.page++
            this.getDataList();
        },
        likeClick(data) {
            if (!data.isLike) {
                data.favCount++;
            } else {
                data.favCount--;
            }
            data.isLike = !data.isLike
        },
        toggleLike(row) {
            if (!row.liked) {
                row.playNum++;
            } else {
                row.playNum--;
            }
            row.liked = !row.liked

        },
    }
}
</script>

<style lang="less">
.video {
    min-height: 100vh;
    background: #F2F2F2;

    .clickLike {
        display: inline-block;
        position: relative;
        float: right;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9D9D9D;
        line-height: 21px;

        .video-like {
            margin-left: 14px;
        }

        .collect {
            color: #EEC068;

            .van-icon {
                color: #EEC068 !important;
            }
        }

        .like {
            color: #EE6D68;

            .van-icon {
                color: #EE6D68 !important;
            }
        }
    }

    .van-nav-bar__title {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #313233;
    }

    .van-icon {
        font-size: 19px;
        color: #5E5E5E !important;
    }

    .video-card {
        margin-bottom: 10px;

        .video-img {
            width: 90px;
            height: 100px;
            float: left;
            object-fit: cover;
            margin-right: 15px;
        }

        .video-title {
            display: block;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .video-cont {
            display: block;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #5E5E5E;
            margin-top: 54px;
            padding-bottom: 10px;
        }

        .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #9D9D9D;
            line-height: 18px;
            border-top: 1px solid #F2F2F2;
            padding: 15px 0 10px;
        }
    }
}
</style>