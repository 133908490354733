import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userType: 1,
    user: {},
    userInfo: {}
  },
  mutations: {
    setUserType (state, userType) {
      state.userType = userType
    },
    setUser (state, user) {
      state.user = user
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {}
})
