<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data () {
    return {
      active: 0
    }
  }
}
</script>
<style lang="less">
 #app{
 }
 .tab-bar{
   .van-tabbar-item--active{
     color: #BB0720;
   }
 }
</style>
