<template>
    <div class="policy">
       <!-- <van-nav-bar title="图文专题" left-arrow @click-left="$router.go(-1)"></van-nav-bar> -->
        <van-tabs v-model="activeName" @click="tabsClick">
            <van-tab v-for="item in tabsList" :title="item.title" :name="item.tagId">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
                    <van-cell v-for="(item, index) in policyList" :key="item.index" center size="large" :title="item.title"
                        :label="item.createTime" @click="policyClick(item)" />
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
    name: "PolicyList",
    data() {
        return {
            tabsList: [
                {
                    title: "推荐",
                    tagId: ''
                },
                {
                    tagId: 66,
                    title: "人工智能"
                },
                {
                    tagId: 65,
                    title: "计算机科学"
                },
                {
                    tagId: 64,
                    title: "软件工程"
                },
                {
                    tagId: 63,
                    title: "大数据"
                },
                {
                    tagId: 62,
                    title: "物联网工程"
                },
                {
                    tagId: 61,
                    title: "信息对抗"
                },
            ],
            activeName: "",
            finished: false,
            loading: false,
            page: 1,
            pageSize: 10,
            policyList: []
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            this.policyList = []
            this.$ajax.get('/api/healthknow/getHealthKnowledgeList', {
                params: {
                    pageNum: this.page,
                    dataSize: this.pageSize,
                    orderKey: 'viewnum_desc',
                    tag: this.activeName
                }
            }).then((response) => {
                if (response.data.code === 500) {
                    this.finished = true
                    return
                }
                if (response.data.data.length === 0) {
                    this.finished = true
                    return
                }
                this.policyList = response.data.data
                this.finished = true;
                this.loading = false;
            })
        },
        tabsClick() {
            this.page = 1
            this.getDataList()
        },
        onLoad() {
            this.page++
            this.getDataList();
        },
        policyClick(row) {
            this.$router.push('/policyDetails?id=' + row.knowledgeId);
        }
    }
}
</script>

<style lang="less">
.policy {
    .van-nav-bar__title {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #313233;
    }

    .van-icon {
        font-size: 19px;
        color: #5E5E5E !important;
    }

    .van-cell__title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;

        .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }
}
</style>